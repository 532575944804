<template>
    <section>
        <v-data-table :headers="headers.filter(header => header.visibleFor.includes(role) || role === 'admin' || 'agent')" flat
                      :items="tasks"
                      hide-default-footer
                      :items-per-page="10">
            <!-- Customer -->
            <template v-slot:item.customer="{item}">
                <div v-if="getAttributeValue(item.attributes, 'Customer')" class="mb-0 text-xl-h6">
                {{getAttributeValue(item.attributes, 'Customer').full_name}}
                </div>
                <div v-else>
                <v-chip>none</v-chip>
                </div>
            </template>
            <!-- Details -->
            <template v-slot:item.details="{item}">
                <h1 class="mb-0 mt-5 mt-sm-0" @click="viewTask(item)">
                    <span v-if="role !== 'nurse'">#{{item.id}} <b class="text--accent-3">/</b></span>
                    {{item.title}}</h1>
            </template>
            <!-- Due Date -->
            <template v-slot:item.due="{item}">
                <div class="my-sm-6 my-3">{{item.due_date | moment("DD MMM YY") }}</div>
            </template>
            <template v-slot:item.controls="{item}">
                <v-btn class="hidden-xs-only" @click="viewTask(item)">View</v-btn>
            </template>
        </v-data-table>

        <v-pagination
                v-model="page"
                @input="paginationChange"
                :length="meta.last_page"
                :total-visible="6"
        ></v-pagination>
    </section>
</template>
<script>
    import tasks from "@/api/modules/tasks";
    import {EventBus} from "@/bootstrap/EventBus";
    import {mapGetters} from 'vuex';
    import customers from "@/api/modules/customers";

    export default {
        name: 'CustomersTasksTable',
        props: ['defaults'],
        data: () => ({
            page: 1,
            headers: [
                {text: 'Customer', value: 'customer', visibleFor: ['admin', 'dispo']},
                {text: 'Details', value: 'details', visibleFor: ['admin', 'dispo']},

                {text: 'Controls', value: 'controls', visibleFor: ['admin', 'dispo']},
            ],
            tasks: [],
            meta: {},
            filters: {},
        }),
        created() {
            this.filters = this.defaults;

            this.fetchTasks();

            EventBus.$on('tasks-filters', filters => {
                this.page = 1;
                if (filters) {
                    this.filters = filters;
                }
                this.fetchTasks();
            })
        },
        methods: {
            fetchTasks: function () {
                customers.fetchCustomersWithTotalTasks({...this.filters, status: 1, page: this.page}).then(res => {
                  this.tasks = res.data.data;
                  this.meta = res.data.meta;
                })
            },
            getAttributeValue(attributes, attrName) {
                const result = attributes.find(a => a.name === attrName)
                if (result) return result.value
                return false
            },
            viewTask: function (task) {
                EventBus.$emit('task-show-dialog', task);
            },
            paginationChange: function () {
                this.filters.page = this.page;
                this.fetchTasks();
            },
        },
        beforeDestroy() {
            EventBus.$off('tasks-filters');
        },
        computed: {
            ...mapGetters('auth', ['role']),
        }
    }
</script>
