<template>
    <section class="d-flex filters">
        <v-sheet max-width="250px" v-if="!hidden.customer">

        </v-sheet>
        <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
    </section>
</template>

<script>
    import customers from "@/api/modules/customers";
    import {EventBus} from "@/bootstrap/EventBus";
    import SelectCustomerDropdown from "@/components/forms/SelectCustomerDropdown";
    import SelectAssigneeDropdown from "@/components/forms/SelectAssigneeDropdown";

    export default {
        name: 'TasksTableFilters',
        props: ['hidden', 'defaults'],
        components: {SelectAssigneeDropdown, SelectCustomerDropdown},
        data: () => ({
            dictionaries: {
                customers: [],
            },
            filters: {
                customer_id: null,
                page: 1,
            },

        }),
        created: function () {
            this.filters = Object.assign({}, this.defaults);

            this.fetchDictionaries();
        },
        methods: {
            fetchDictionaries: function () {
                customers.getCustomersDictionary().then(res => {
                    this.dictionaries.customers = res.data;
                });
            },
            /** Emit Filters event on Filters Change **/
            filter: function () {
                EventBus.$emit('tasks-filters', this.filters);
            },
            resetFilters: function () {
                /** On Project Selection we reset filters to default **/
                this.filters = Object.assign({}, this.defaults);
                this.filter();
            },
        },
    }
</script>
<style lang="css">
    .filters .v-sheet {
        margin-right: 12px;
    }
</style>
