<template>
    <section id="users-list-page">
        <v-col md="12" xl="10" xs="12">
            <v-card>
                <v-card-title class="pa-sm-5">
                    Customers Tasks
                </v-card-title>
                <v-card-subtitle class="d-flex">
                  <div>
                    View, Manage <code>Cliebts Tasks</code>
                  </div>
                </v-card-subtitle>
                <v-card-text>
                    <v-sheet>
                        <tasks-table-filters :hidden="{}" :defaults="{}"/>
                        <tasks-table :defaults="{page: 1}"/>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-col>
    </section>
</template>

<script>
    import TasksTable from "./components/CustomersTasksTable";
    import {EventBus} from "@/bootstrap/EventBus";
    import TasksTableFilters from "./components/CustomersTasksFilters";

    export default {
        name: 'CustomersTasksList',
        components: {TasksTableFilters, TasksTable},
        methods: {
            onAddTaskClick: function () {
                EventBus.$emit('task-create-dialog')
            },
            onQSVisitClick: function () {
                EventBus.$emit('task-qs-visit-dialog')
            },
        }
    }
</script>
